
.MyNameContainer  {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: rgba(178, 178, 178, 0);
    min-width: 100%;
    padding: -3em;
    }

.ACharacterInName {
    font-size: 3em;
    font-weight: bold;
    color: rgba(255,255,255,1);
    font-family: 'Josefin Sans', cursive;
}