@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  background: rgb(2,0,36);
  font-family: 'Josefin Sans', cursive;
}

.TestOverlay {
  position: fixed;
  color: white;
  z-index: 189798;
}

.AppOverlay {
  opacity: 0.5;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.Arrow {
  cursor: pointer;
}

.AboutMe {
  height: 100vh;
  background-color: black;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.AboutMeText {
  min-width: 320px;
  max-width: 38em;
  margin: 0.5em 1em 25px;
  padding: 0.25em;
  text-align: center;
}

.AboutMeList {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  text-align: center;
}

.ArrowPlacer {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 17799;
  font-size: smaller;

}

.SuperHeader {
  position: fixed;
  top: 0.3em;
  width: 100%;
  overflow: hidden;
  height: 5em;
  background-color: rgba(0, 0, 0, 0);
  z-index: 17800;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  font-size: smaller;
  align-items: stretch;
}

.Header {
  background-color: rgba(0, 0, 0, 0);
  z-index: 17800;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-end;
  font-size: smaller;
  flex-basis: 38em;

}

.HeaderItem {
  flex-grow: 1;
  min-width: 2em;
  flex-basis: 100%;
  padding: 0.3em;
  height: 100%;

}

.GlassCard {
  background: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 0.125em 0.5em 0 rgba( 0, 0, 0, 0.37 );
  backdrop-filter: blur( 2.0px );
  -webkit-backdrop-filter: blur(10.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

}

#tsparticles {
  position : absolute;
  width:100%;
  height:100%;
}

.GlassCardVert {
  background: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2.0px );
  -webkit-backdrop-filter: blur(10.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Horizontal-Placer {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 100%;
}


.Middle {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 35% 35% 15%;
  background: rgba(76, 175, 80, 0);
  flex-grow: 1;
  overflow: hidden;
}

.Svg-middle {
  height: 100%;
  width: 100%;
  background: rgba(76, 175, 80, 0);
}

.Skills {
  height: 100vh;
  background-color: #00ACD7;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.Writing {
  height: 100vh;
  background-color: black;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.Contact {
  height: 100vh;
  background-color: #000000;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.ContactSVG {
  fill: white;
}

.SvgAnchor {
  z-index: 1780;
}
